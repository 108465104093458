.footer {
  width: 100%;
  padding: 2rem 0;
  background-color: rgb(19, 19, 0.8);
}

.nav {
  display: flex;
  flex-direction: row;
  align-content: space-between;
}

.nav li {
  padding: 20px;
  font-size: 1.2rem;
}

.center {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  align-items: center;
}

.location {
  display: flex;
  margin-bottom: 0.8rem;
}

.location p {
  line-height: 30px;
}

.right {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

h4 {
  font-size: 1.2rem;
  padding-bottom: 0.7rem;
}

.center p {
  font-size: 1.2rem;
  padding: 10px;
}

@media screen and (max-width: 640px) {
  .footer-container {
    grid-template-columns: 1fr;
  }
}
