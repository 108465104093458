.imgHeader {
  background-size: cover;
  background-position: center;
  height: auto;
  max-width: 80%;
  margin: 0 auto;
  padding-top: 15px;
  justify-content: center;
  display: block;
}

.container {
  width: 50%;
  padding: 1.2rem 1rem;
  background-color: #1a1919;
  margin-top: 20px;
  border-radius: 10px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  margin-bottom: 30px;
}

@media screen and (max-width: 740px) {
  .container {
    font-size: 14px;
    padding: 0%;
    width: 80%;
  }
}

.topic {
  padding: 30px 30px 0px 30px;
}

@media screen and (max-width: 740px) {
  .topic {
    font-size: 16px;
    padding: 2em;
  }
}

.title {
  justify-content: center;
  margin: 0 auto;
  display: flex;
  padding: 10px 0px;
  font-size: 45px;
}

@media screen and (max-width: 740px) {
    .title {
      font-size: 2.5em;
     
    }
  }

.section {
    padding: 30px 30px 0px 30px
}

@media screen and (max-width: 740px) {
  .section {
    padding-left: 30px;
    padding-top: 10px;
  }
}
p {
  line-height: 1.8;
}

.topic {
  color: white;
  list-style-type: disc;
}
