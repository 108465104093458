.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  position: fixed;
  width: 100%;
  height: 90px;
  z-index: 10;
}
.header-bg{
  background-color: rgb(0, 0, 0, 0.85);
  transition: 0.5s;
}

.nav-menu {
  display: flex;
}
.nav-menu li {
  padding: 0 1rem;
}

.nav-menu li a {
  font-size: 1.2rem;
  font-weight: 500;
}
.hamburger {
  display: none;
}

@media screen and (max-width: 740px){
  .portfolio {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 740px){
  .header-bg {
    background-color: rgb(0, 0, 0, 0.85);

  }
  .header {
    background-color: rgb(0, 0, 0, 0.85);
    height: 70px;

  }
}

@media screen and (max-width: 1040px) {
  .nav-menu {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: rgb(0, 0, 0, 0.9);
    position: absolute;
    top: 0;
    left: -100%;
    z-index: -3;
    transition: 0.3s;
  }
  .nav-menu.active {
    left: 0;
  }
  .nav-menu li {
    padding: 1rem 0;
  }
  .nav-menu li a {
    font-size: 2rem;
  }
  .hamburger {
    display: initial;
  }
}
